import React, { memo, ReactElement } from 'react';
import { translate } from '@uphillhealth/i18n';
import { styled } from '@uphillhealth/theme';

interface PaginationSummaryProps {
  currentPage: number;
  currentPageSize: number;
  totalRows: number;
}

const PaginationSummaryStyled = styled('div', {
  color: '$neutral500',
  fontSize: '$extraSmall',
});

export const PaginationSummary = memo(({ currentPage, currentPageSize, totalRows }: PaginationSummaryProps): ReactElement => {
  const start = currentPageSize > 0 ? currentPage * currentPageSize + 1 : currentPageSize;

  const computedEnd = start - 1 + currentPageSize;
  const end = computedEnd > totalRows ? totalRows : computedEnd;

  return (
    <PaginationSummaryStyled data-testid="PaginationSummary">
      {translate('Showing {{start}}-{{end}} of {{total}}', {
        end: end,
        start: start,
        total: totalRows,
      })}
    </PaginationSummaryStyled>
  );
});
