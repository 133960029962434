import { styled } from '@uphillhealth/theme';

export const PageSelectorStyled = styled('div', {
  display: 'flex',
  flexDirection: 'row',
});

export const PageButton = styled('button', {
  color: '$neutral500',
  fontSize: '$extraSmall',
  border: 'none',
  padding: '$1',
  borderRadius: 4,
  variants: { active: { true: { background: '$neutral200' } }, darker: { true: {} } },
  compoundVariants: [
    {
      darker: true,
      active: true,
      css: {
        color: '$neutral900',
      },
    },
  ],
});

export const PageSeparator = styled('div', {
  alignSelf: 'flex-end',
  color: '$neutral600',
  fontSize: '$base',
});
