import React, { ReactElement, useCallback, useReducer } from 'react';
import { simpleReducer } from '@/uphillhealth/methods';
// app
import { useApplicationContext } from '@/src/providers';
// self
import { WorkspaceContextProviderProps, WorkspaceContextProviderState, WorkspaceContextValues } from './types';
import { initialContextValue, WorkspaceContext } from './context';
import { WorkspaceSocket } from './WorkspaceSocket';

export const WorkspaceContextProvider = ({ children }: WorkspaceContextProviderProps): ReactElement => {
  const { currentWorkspace } = useApplicationContext();

  const [state, dispatcher] = useReducer(simpleReducer<WorkspaceContextProviderState>, {
    alerts: initialContextValue.alerts,
    tasks: initialContextValue.tasks,
    onJourneyDirectoryUpdateCallback: null,
  });

  const onJourneyDirectoryUpdateCallback = state.onJourneyDirectoryUpdateCallback;
  const executeOnJourneyDirectoryUpdateCallback = useCallback(() => {
    if (onJourneyDirectoryUpdateCallback) {
      onJourneyDirectoryUpdateCallback();
    }
  }, [onJourneyDirectoryUpdateCallback]);

  const shouldUpdateAlerts = useCallback(
    (careTeam: number, self: number, tasks: number) => {
      return (
        (!Number.isNaN(Number(tasks)) && state.tasks !== tasks) ||
        (!Number.isNaN(Number(careTeam)) && state.alerts.careTeam !== careTeam) ||
        (!Number.isNaN(Number(self)) && state.alerts.self !== self)
      );
    },
    [state.alerts.careTeam, state.alerts.self, state.tasks],
  );

  const setWorkspaceUserActivity = useCallback<WorkspaceContextValues['setWorkspaceUserActivity']>(
    (careTeam, self, tasks) => {
      if (shouldUpdateAlerts(careTeam, self, tasks)) {
        executeOnJourneyDirectoryUpdateCallback();
        dispatcher({
          alerts: {
            careTeam,
            self,
          },
          tasks,
        });
      }
    },
    [executeOnJourneyDirectoryUpdateCallback, shouldUpdateAlerts],
  );

  const setJourneyUpdate = useCallback<WorkspaceContextValues['setJourneyUpdate']>(
    (journeyUpdate) => {
      if (
        journeyUpdate &&
        Array.isArray(journeyUpdate.patientSessionUpdateDtos) &&
        journeyUpdate.patientSessionUpdateDtos.some((dto) => {
          return [
            'PROGRESS_UPDATE',
            'NEW_USER_RESPONSE',
            'TASK_COMPLETED',
            'ALERT_UPDATE',
            'CATEGORY_UPDATE',
            'CARE_PLAN_STATUS_UPDATE',
          ].includes(dto.updateType);
        })
      ) {
        const updateAlerts = shouldUpdateAlerts(journeyUpdate.userAlerts.team, journeyUpdate.userAlerts.own, journeyUpdate.userAlerts.tasks)
          ? {
              alerts: { careTeam: journeyUpdate.userAlerts.team, self: journeyUpdate.userAlerts.own },
              tasks: journeyUpdate.userAlerts.tasks,
            }
          : {};

        dispatcher({ journeyUpdate, ...updateAlerts });
      }
    },
    [executeOnJourneyDirectoryUpdateCallback],
  );

  const cleanJourneyUpdateState = useCallback(() => {
    dispatcher({ journeyUpdate: undefined });
  }, []);

  const setOnJourneyDirectoryUpdateCallback = useCallback<WorkspaceContextValues['setOnJourneyDirectoryUpdateCallback']>((callback) => {
    dispatcher({ onJourneyDirectoryUpdateCallback: callback });
  }, []);

  const workspaceContextValues: WorkspaceContextValues = {
    alerts: state.alerts,
    tasks: state.tasks,
    cleanJourneyUpdateState,
    journeyUpdate: state.journeyUpdate,
    setJourneyUpdate,
    setOnJourneyDirectoryUpdateCallback,
    setWorkspaceUserActivity,
  };

  return (
    <WorkspaceContext.Provider value={workspaceContextValues}>
      {currentWorkspace?.pathwayPatientSessions && <WorkspaceSocket />}
      {children}
    </WorkspaceContext.Provider>
  );
};

export default WorkspaceContextProvider;
