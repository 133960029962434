import React, { ReactElement } from 'react';
import dynamic from 'next/dynamic';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { I18n, I18nContext } from '@uphillhealth/i18n';
import { localeAssets } from '@/public/locales';
import { Provider as TooltipProvider } from '@radix-ui/react-tooltip';
import { ServiceProvider } from '@uphillhealth/react-service-provider';
// legacy
import { store } from '@/store';
import '../components/AxiosInterceptor/AxiosInterceptor';
// app
import { ApplicationContext, ApplicationContextProvider } from '@/src/providers';
import { SplashLoading } from '@/components/shared/SplashLoading';
import { LazyComponentLoader } from '@/src/components/commons';
import {
  PatientPreSessionService,
  PatientService,
  PatientSessionService,
  PathwayService,
  ValuesListService,
  TerminologyService,
  ResourcesService,
  ExamsService,
  PatientSessionSummaryService,
} from '@/src/services';
import { PatientContactService } from './services/PatientContactService/PatientContactService';
import { PatientNotesService } from './services/PatientNotesService';
import { PatientSessionSummaryReportService } from '@/src/services/PatientSessionSummaryReportService';

const LazyApplicationRouteHandler = dynamic(() => import('./ApplicationRouteHandler'), { loading: LazyComponentLoader });
const LazyBaseLayout = dynamic(() => import('./components/Application/ApplicationLayout/ApplicationLayout'), {
  loading: LazyComponentLoader,
});

interface ApplicationEntryPointProps {
  Component: typeof React.Component;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pageProps: any;
}

const services = [
  PatientPreSessionService,
  PatientService,
  PatientSessionService,
  PathwayService,
  ValuesListService,
  TerminologyService,
  PatientContactService,
  PatientNotesService,
  ResourcesService,
  ExamsService,
  PatientSessionSummaryService,
  PatientSessionSummaryReportService,
];

export const ApplicationEntryPoint = ({ Component, pageProps }: ApplicationEntryPointProps): ReactElement => {
  return (
    <ApplicationContextProvider components={{ loading: <SplashLoading />, error: <SplashLoading /> }}>
      <ApplicationContext.Consumer>
        {({ authorizationToken, currentInstitution, currentUser }) => (
          <I18n
            assets={localeAssets}
            cookieAttributes={{ domain: `${process.env.COOKIE_DOMAIN}`, expires: 10000 }}
            initialLanguage={currentUser?.language ? currentUser.language.replace('_', '-') : undefined}
          >
            <I18nContext.Consumer>
              {({ language }) => (
                <TooltipProvider delayDuration={75}>
                  <SnackbarProvider
                    maxSnack={3}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <ServiceProvider
                      authorizationToken={`Bearer ${authorizationToken}`}
                      institutionId={currentInstitution?.id}
                      language={language}
                      services={services}
                      serviceConfigurations={{
                        awsTraceIdHeader: Boolean(process.env.NEXT_PUBLIC_AWS_TRACEID),
                        baseUrl: `${process.env.NEXT_PUBLIC_GATEWAY_SERVICE}`,
                        customServiceBaseUrl: {
                          [TerminologyService.name]: `${process.env.NEXT_PUBLIC_GATEWAY_TERMINOLOGY}`,
                        },
                      }}
                    >
                      <Provider store={store}>
                        <LazyApplicationRouteHandler>
                          <LazyBaseLayout>
                            <Component {...pageProps} />
                          </LazyBaseLayout>
                        </LazyApplicationRouteHandler>
                      </Provider>
                    </ServiceProvider>
                  </SnackbarProvider>
                </TooltipProvider>
              )}
            </I18nContext.Consumer>
          </I18n>
        )}
      </ApplicationContext.Consumer>
    </ApplicationContextProvider>
  );
};
