import React, { Fragment, ReactElement, useCallback, useMemo } from 'react';
// self
import { PaginationProps } from '../../types';
import { PageButton, PageSelectorStyled, PageSeparator } from './styles';
import { isDesktop } from 'react-device-detect';

enum PaginateType {
  page = 1,
  separator = 2,
}

export const PageSelector = ({ css, currentPage, setCurrentPage, totalPages, darker }: PaginationProps): ReactElement => {
  const pagination = useMemo(() => {
    return new Array(totalPages).fill('');
  }, [totalPages]);

  const onClickFactory = useCallback((isCurrent: boolean, pageNumber: number): undefined | (() => void) => {
    return isCurrent ? undefined : () => setCurrentPage(pageNumber);
  }, []);

  return (
    <PageSelectorStyled
      css={{
        gap: !isDesktop ? 0 : '$1',
        ...css,
      }}
    >
      {pagination.map((_, i) => {
        const isCurrent = currentPage === i;
        console.log(isCurrent, currentPage, i);
        return i > 0 && totalPages - 1 > i && (i > currentPage + 2 || i < currentPage - 2) ? (
          <Fragment key={`${i}`} />
        ) : (
          <PageButton
            key={`${i}`}
            onClick={onClickFactory(isCurrent, i)}
            active={isCurrent}
            darker={darker}
            css={{
              ...(!isDesktop && { padding: '$2 $3' }),
              ...(i === currentPage - 2 &&
                i > 1 && {
                  '&:before': {
                    content: '...',
                    marginRight: !isDesktop ? '$6' : '$3',
                  },
                }),
              ...(i === currentPage + 2 &&
                i < totalPages - 2 && {
                  '&:after': {
                    content: '...',
                    marginLeft: !isDesktop ? '$6' : '$3',
                  },
                }),
            }}
          >
            {i + 1}
          </PageButton>
        );
      })}
    </PageSelectorStyled>
  );
};
