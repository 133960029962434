import { TranslateMethod } from '@uphillhealth/i18n';
import axios, { AxiosError, CancelTokenSource } from 'axios';
import { GetServerSidePropsContext, NextPageContext } from 'next';
import nextCookie from 'next-cookies';
import { SnackbarMessage, OptionsObject, SnackbarKey } from 'notistack';
import { getAuthorizationToken } from '@/src/helpers/authentication';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const authRequestOptions = (
  cancelToken?: CancelTokenSource | null | undefined,
  ctx?: NextPageContext | GetServerSidePropsContext,
  token?: string,
): any => {
  const authToken = getAuthorizationToken();

  const options =
    authToken === undefined || authToken === null
      ? {}
      : {
          headers: {
            Authorization: `Bearer ${token ? token : ctx ? nextCookie(ctx).access_token : authToken}`,
          },
        };
  return cancelToken ? { ...options, cancelToken: cancelToken.token } : options;
};

export const handleAxiosError = (
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
  error: any,
  translate: TranslateMethod,
): void => {
  if (axios.isAxiosError(error)) {
    const serverError = error as AxiosError<{ error: string }>;
    if (serverError && serverError.response) {
      enqueueSnackbar(serverError.response.data.error);
    }
  }
  enqueueSnackbar(translate('GenericError'));
};
