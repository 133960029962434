import React, { Fragment, ReactElement } from 'react';
import { useI18n } from '@uphillhealth/i18n';
import { Tooltip } from '@/uphillhealth/components';
// app
import { CommunicationInteractionState, CommunicationOverviewState, JourneyCommunicationAPI, JourneyObservations } from '@/src/models';
import { HillyAvatar } from '@/uphillhealth/icons';
// self
import { CommunicationStatus, HillyAvatarContainer, IconContainer } from './styles';

const getIconResources = (
  interactionStatus: CommunicationInteractionState,
  observations: JourneyObservations[],
  overviewState: CommunicationOverviewState,
): { message: string; statusIcon: string } => {
  if (overviewState === CommunicationOverviewState.finished) {
    return {
      message: 'CommunicationFinished',
      statusIcon: '/images/communication/finished.svg',
    };
  }

  if (overviewState === CommunicationOverviewState.scheduled) {
    return {
      message: 'ScheduledCommunication',
      statusIcon: '/images/communication/scheduled.svg',
    };
  }

  if (observations) {
    if (observations.includes(JourneyObservations.invalidPhoneNumber)) {
      return {
        message: "Communication failed due to patient's invalid contact",
        statusIcon: '/images/communication/invalidNumber.svg',
      };
    }
  }

  if (overviewState === CommunicationOverviewState.waitingResponse) {
    if (interactionStatus === CommunicationInteractionState.missing) {
      return {
        message: 'MissingCommunication',
        statusIcon: '/images/communication/missingAnswers.svg',
      };
    }

    return {
      message: 'WaitingCommunication',
      statusIcon: '/images/communication/waitingResponses.svg',
    };
  }

  if (overviewState === CommunicationOverviewState.suspended) {
    return {
      message: 'SuspendedCommunication',
      statusIcon: '/images/communication/suspended.svg',
    };
  }

  return {
    message: '',
    statusIcon: '',
  };
};

interface HillyCommunicationIconProps {
  interactionStatus: CommunicationInteractionState;
  observations?: JourneyObservations[];
  overviewState: JourneyCommunicationAPI['overviewState'];
}

export const HillyCommunicationIcon = ({
  interactionStatus,
  observations = [],
  overviewState,
}: HillyCommunicationIconProps): ReactElement => {
  const { translate } = useI18n();

  if (!overviewState || overviewState === CommunicationOverviewState.error) {
    return <Fragment />;
  }

  const { message, statusIcon } = getIconResources(interactionStatus, observations, overviewState);

  return (
    <Tooltip content={translate(message)}>
      <IconContainer data-testid="HillyCommunicationIcon">
        <CommunicationStatus alt="Communication Status" src={statusIcon} />
        <HillyAvatarContainer>
          <HillyAvatar />
        </HillyAvatarContainer>
      </IconContainer>
    </Tooltip>
  );
};
