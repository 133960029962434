export enum RouteIdentifiers {
  routePathwayIdAttribute = '#route-pathway',
  progressLatestDoneElementKey = 'latestDoneElement',
  progressFirstToBeDoneElementKey = 'firstToBeDoneElement',
  sidebarCommunicationIdAttribute = 'SidebarContainerCommunication',
  sidebarDetailsIdAttribute = 'SidebarContainerDetails',
  sessionStorageAutomationOnboarding = 'automationOnboarding.',
  sessionStorageMetadataPrefix = 'positionsMetadata.',
  sessionStorageFirstScrollPrefix = 'firstScroll.',
  sessionStoragePreventLoopCrash = 'preventLoop.',
  sessionStorageStandaloneProgressPrefix = 'standaloneProgress.',
  sidebarExtraKeysButtonsPortal = 'SidebarExtraKeysButtonsPortalId',
  sidebarExtraKeysDropdownPortal = 'SidebarExtraKeysDropdownPortalId',
  extendedReportContainer = 'ExtendedReportContainerId',
  extendedReportPortal = 'extendedReportPortal',
  sidebarMomentSummary = 'SidebarMomentSummary',
  extendedElementPortal = 'extendedElementPortal',
}

export const RouteIdGlue = '#';
export const RouteGatewayOptionIdGlue = '@';

export const RouteJourneyCreationQueryParam = 'journeyCreated';

export const RouteProvisionalStepId = 'PROVISIONAL_STEP_ID';

export enum TenantIdentifiers {
  sessionStorageLayoutKey = 'contextLayout',
  sessionStorageSummaryHiddenKey = 'summaryHidden',
}
