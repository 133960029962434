import React, { ReactElement, ReactNode } from 'react';
import * as DialogPrimitives from '@radix-ui/react-dialog';
// self
import { DialogOverlay, DialogRoot, DialogCloseIcon, DialogContainer } from './styles';
import { StitchesProps } from '@uphillhealth/theme';
import { CSS } from '@stitches/react';

export interface DialogProps extends Omit<DialogPrimitives.DialogProps, 'children'>, StitchesProps {
  children: ReactNode | ReactNode[];
  onClose?: () => void;
  showCloserIcon?: boolean;
  trigger?: ReactNode | ReactNode[];
  overlayProps?: { css?: CSS; onClick?: () => void };
  autofocus?: boolean;
}

/* Implementation from radix primitives.
 * https://www.radix-ui.com/docs/primitives/components/dialog
 */
export const Dialog = ({
  children,
  css,
  onClose,
  trigger,
  showCloserIcon = true,
  overlayProps,
  autofocus = true,
  ...dialogProps
}: DialogProps): ReactElement => {
  return (
    <DialogRoot {...dialogProps} data-testid="Dialog">
      {trigger && <DialogPrimitives.Trigger asChild>{trigger}</DialogPrimitives.Trigger>}
      <DialogPrimitives.Portal>
        <DialogOverlay {...overlayProps} />
        <DialogContainer data-testid="DialogContent" css={css} onOpenAutoFocus={!autofocus ? (e) => e.preventDefault() : undefined}>
          {children}
          {showCloserIcon && (
            <DialogCloseIcon onClick={onClose}>
              <i className="icon-close" />
            </DialogCloseIcon>
          )}
        </DialogContainer>
      </DialogPrimitives.Portal>
    </DialogRoot>
  );
};
