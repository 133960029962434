import { Report, ReportSimplified } from '@/src/services/PatientSessionSummaryReportService/models';
import { MomentsSummary } from '@/src/services/PatientSessionSummaryService/models';
import { Service } from '@uphillhealth/react-service-provider';

export class PatientSessionSummaryReportService extends Service {
  usePostGenerateReports() {
    return this.request<ReportSimplified>({
      method: 'POST',
      url: '/[institutionId]/summary/[journeyId]/report',
    });
  }

  useGetReportsList() {
    return this.useQuery<ReportSimplified[]>({
      method: 'GET',
      url: '/[institutionId]/summary/[journeyId]/report',
    });
  }

  useGetReport(language: string) {
    return this.request<Report>({
      headers: {
        'Accept-language': language,
      },
      method: 'GET',
      url: '/[institutionId]/summary/[journeyId]/report/[reportId]',
    });
  }

  usePutReportMoment(data: { summary: string; fromReport: boolean }) {
    return this.request<Record<string, MomentsSummary>>({
      data,
      method: 'PUT',
      url: '/[institutionId]/summary/[journeyId]/report/[reportId]/moment/[momentId]',
    });
  }
}
