export enum RouteElementTypes {
  action = 'ACTION',
  calculator = 'CALCULATOR_REFERENCE',
  criteria = 'CRITERIAS',
  event = 'EVENT',
  eventFinal = 'EVENT_FINAL',
  eventInitial = 'EVENT_INITIAL',
  eventIntermediate = 'EVENT_INTERMEDIATE',
  gateway = 'GATEWAY',
  gatewayExclusive = 'GATEWAY_EXCLUSIVE',
  gatewayInclusive = 'GATEWAY_INCLUSIVE',
  gatewayParallel = 'GATEWAY_PARALLEL',
  inbound = 'INBOUND',
  keyMoment = 'KEY_MOMENT',
  reference = 'PATHWAY_REFERENCE',
  timeEvent = 'TIME_EVENT',
}

export type OnlyRouteElementTypes =
  | RouteElementTypes.action
  | RouteElementTypes.calculator
  | RouteElementTypes.event
  | RouteElementTypes.gateway
  | RouteElementTypes.keyMoment
  | RouteElementTypes.reference
  | RouteElementTypes.timeEvent;

export const RouteBlockElement = [RouteElementTypes.calculator, RouteElementTypes.gateway];
