// global css
import '../styles/index.scss';
import 'material-icons/iconfont/filled.css';
// libraries
import React, { Fragment } from 'react';
import { useRouter } from 'next/router';
import { createWrapper } from 'next-redux-wrapper';
// legacy
import { store } from '@/store';
import Meta from '@/components/Meta';
import '../components/AxiosInterceptor/AxiosInterceptor';
// app
import { ApplicationEntryPoint } from '@/src/ApplicationEntryPoint';

interface ApplicationMainProps {
  Component: typeof React.Component;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pageProps: any;
}

function ApplicationMain(props: ApplicationMainProps) {
  const router = useRouter();

  const directRenderPathNames = ['/auth/cmd', '/auth/kyc', '/chat'];
  if (directRenderPathNames.some((pathName) => pathName === router.pathname)) {
    const { Component, pageProps } = props;
    return <Component {...pageProps} />;
  }

  const isTenantPatientSessionOrDesk = ['/tenant', '/patients-sessions', '/desk'].some((page) => router.pathname.includes(page));

  return (
    <Fragment>
      <Meta title={'UpHill Health'} isTenantPatientSessionOrDesk={isTenantPatientSessionOrDesk} />
      <ApplicationEntryPoint {...props} />
    </Fragment>
  );
}

const makeStore = () => store;
const wrapper = createWrapper(makeStore);

export default wrapper.withRedux(ApplicationMain);
