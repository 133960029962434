import React, { ReactElement, ReactNode } from 'react';
import * as DropdownPrimitives from '@radix-ui/react-dropdown-menu';
// self
import { DropdownDownContentStyled } from './styles';
import { CSS } from '@stitches/react';

interface DropdownContentProps extends Omit<DropdownPrimitives.DropdownMenuContentProps, 'children'> {
  children: ReactNode | ReactNode[];
  css?: CSS;
}

export const DropdownContent = ({ children, ...props }: DropdownContentProps): ReactElement => {
  return (
    <DropdownPrimitives.Portal>
      <DropdownDownContentStyled {...props}>{children}</DropdownDownContentStyled>
    </DropdownPrimitives.Portal>
  );
};
