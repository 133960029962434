export enum JourneyStatus {
  draft = 'DRAFT',
  active = 'ACTIVE',
  revoked = 'REVOKED',
  completed = 'COMPLETED',
  unknown = 'UNKNOWN',
}

export const CANCEL_JORNEY_REASON: { [key: string]: { id: string; reason: string } } = {
  PATIENT_NOT_ACCEPTED: { id: 'PATIENT_NOT_ACCEPTED', reason: 'PatientNotAccepted' },
  PATIENT_STATUS_CHANGED: { id: 'PATIENT_STATUS_CHANGED', reason: 'PatientStatusChanged' },
  PATIENT_FOLLOWUP_CHANGED: { id: 'PATIENT_FOLLOWUP_CHANGED', reason: 'PatientFollowUpChanged' },
  PATIENT_IN_WRONG_JOURNEY: { id: 'PATIENT_IN_WRONG_JOURNEY', reason: 'PatientInWrongJourney' },
  PATIENT_DECEASED: { id: 'PATIENT_DECEASED', reason: 'PatientDied' },
  PATIENT_OTHER_REASON: { id: 'PATIENT_OTHER_REASON', reason: 'PatientOtherReason' },
  LIBER_PAYMENT_EXPIRED: { id: '', reason: '' },
};
