import cookie from 'js-cookie';
import { ACCESS_TOKEN } from '@/constants/auth';

type SetAuthorizationToken = (authorizationToken: string) => void;

/* istanbul ignore next */
export const setAuthorizationToken: SetAuthorizationToken = (authorizationToken) => {
  cookie.set(ACCESS_TOKEN, authorizationToken, { domain: `${process.env.COOKIE_DOMAIN}` });
  window.sessionStorage.setItem(ACCESS_TOKEN, authorizationToken);
};
