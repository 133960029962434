import React, { Fragment, ReactElement, useEffect } from 'react';
import useWebSocket from 'react-use-websocket';
// app
import { InstitutionId } from '@/src/models';
import { useApplicationContext, useWorkspaceContext } from '@/src/providers';
import { getAuthorizationToken } from '@/src/helpers/authentication';
import { WebSocketResponse, WebSocketResponseJourneyUpdate } from '@/src/providers/WorkspaceContextProvider/types';
import { HttpStatusCode } from 'axios';

export const WorkspaceSocket = (): ReactElement => {
  const { authorizationToken: appContextToken, currentInstitution } = useApplicationContext();
  const { setJourneyUpdate, setWorkspaceUserActivity } = useWorkspaceContext();

  const socketEndpoint = `${process.env.NEXT_PUBLIC_WORKSPACE_SOCKET}`;
  const authorizationToken = appContextToken || getAuthorizationToken();

  const openSocketConnection = Boolean(socketEndpoint && currentInstitution && authorizationToken);

  const currentInstitutionId: InstitutionId = currentInstitution?.id || 0;

  const { sendJsonMessage, lastJsonMessage, getWebSocket } = useWebSocket(
    socketEndpoint,
    {
      onOpen: () => sendFirstMessage(),
      shouldReconnect: () => true,
    },
    openSocketConnection,
  );

  const sendFirstMessage = () => {
    sendJsonMessage({ action: 'open', institutionId: currentInstitutionId, token: authorizationToken });
  };

  useEffect(() => {
    if (getWebSocket()?.OPEN && currentInstitutionId) {
      sendJsonMessage({ action: 'open', institutionId: currentInstitutionId, token: authorizationToken });
    }
  }, [authorizationToken, currentInstitutionId, getWebSocket, sendJsonMessage]);

  useEffect(() => {
    if (lastJsonMessage) {
      const activityMessage = lastJsonMessage as WebSocketResponse;
      if (!isNaN(activityMessage.own) && !isNaN(activityMessage.team) && !isNaN(activityMessage.tasks)) {
        setWorkspaceUserActivity(activityMessage.team, activityMessage.own, activityMessage.tasks);
      }

      const journeyMessage = lastJsonMessage as WebSocketResponseJourneyUpdate;
      if (journeyMessage.userAlerts && journeyMessage.id) {
        setJourneyUpdate(journeyMessage);
      }

      if (lastJsonMessage.status && lastJsonMessage.status === HttpStatusCode.Forbidden) {
        setWorkspaceUserActivity(0, 0, 0);
      }
    }
  }, [lastJsonMessage, setJourneyUpdate, setWorkspaceUserActivity]);

  return <Fragment />;
};

export default WorkspaceSocket;
