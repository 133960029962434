import { MomentsSummary } from '@/src/services/PatientSessionSummaryService/models';
import { Service } from '@uphillhealth/react-service-provider';

export class PatientSessionSummaryService extends Service {
  usePostMomentSummary(data: { summary: string; fromReport: boolean }) {
    return this.request<any>({
      data,
      method: 'POST',
      url: '/[institutionId]/summary/[journeyId]/moment/[momentId]',
    });
  }

  useGetMomentSummaries() {
    return this.useMutation<MomentsSummary[]>({
      method: 'GET',
      url: '/[institutionId]/summary/[journeyId]/moment/[momentId]',
    });
  }

  useGetJourneySummaries() {
    return this.request<Record<string, MomentsSummary>>({
      method: 'GET',
      url: '/[institutionId]/summary/[journeyId]',
    });
  }
}
